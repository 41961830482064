import { throwError as observableThrowError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, finalize, catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // count for the requests made
  numberRequests = 0;

  constructor(
    private http: HttpClient,
  ) { }


  private setHeaders(): HttpHeaders {
    if (window.localStorage.getItem('jwtToken')) {
      return new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${window.localStorage.getItem('jwtToken')}`
      });
    } else {
      return new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Accept: 'application/json'
      });
    }
  }

  private formatErrors(error: any) {
    return observableThrowError(error.json());
  }

  post(path: string, body: object = {}): Observable<any> {
    this.numberRequests++;

    const headers: HttpHeaders = this.setHeaders();

    const url = `${environment.apiUrl}${path}`;

    return this.http.post(url, body, { headers })
      .pipe(
        map(
          (response: Response) => {
            return response;
          }
        ),
        catchError(res => {
          this.formatErrors(res);
          return observableThrowError(res.json());
        }),
        finalize(() => {
          this.numberRequests--;
        }
        ),
      );
  }

  isLoading(): boolean {
    return this.numberRequests > 0;
  }
}
