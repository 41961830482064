import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LandingPageContainerService } from 'src/app/_services/landing-page-container.service';

@Component({
  selector: 'app-android',
  templateUrl: './android.component.html',
  styleUrls: ['./android.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AndroidComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    public landingPageContainerService: LandingPageContainerService
    ) { }

  ngOnInit() {
  }

  downloadFile(url) {
    if (url === '') {
      alert ('missing url');
      return;
    }
    const link = document.createElement('a');
    link.href = url;
    link.click();
  }

  activeLanguage() {
    let lang = this.translate.currentLang;

    if (lang == null || lang === '') {
      lang = this.translate.defaultLang;
    }

    return lang;
  }
}
