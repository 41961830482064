import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function checkDeeplinks() {
  // Deep link to your app goes here
  var deeplink = "estorilopen://open/screen/";

  // Try to open the app using its custom URL scheme
  window.location.href = deeplink;

}

checkDeeplinks();
