import { Component, OnInit, HostBinding } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LandingPageContainerService } from 'src/app/_services/landing-page-container.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userLanguage = 'pt-pt';
  userLangShort = 'pt';

  constructor(public translate: TranslateService, public landingPageContainer: LandingPageContainerService ) { }

  ngOnInit() {
    this.useLanguage(this.landingPageContainer.defaultLanguage.toLocaleLowerCase());
  }

  useLanguage(language: string): void {
    document.documentElement.lang  = language;
    this.translate.use(language);
    this.setUserLanguage();
  }

  activeLanguage(): string {
    let lang = this.translate.currentLang;

    if (lang == null || lang === '') {
      lang = this.translate.defaultLang;
    }

    const test = [];
    test.filter(a => a.IsDefault);

    this.setUserLanguage();

    return lang;
  }

  setUserLanguage(): void {
    switch (this.translate.currentLang) {
      case 'pt':
        this.userLanguage = 'pt-pt';
        this.userLangShort = 'pt';
        break;

      case 'en':
        this.userLanguage = 'en-us';
        this.userLangShort = 'en';
        break;
    }
  }

  downloadFile(url) {
    if (url === '') {
      alert ('missing url');
      return;
    }
    const link = document.createElement('a');
    link.href = url;
    link.click();
  }
}
