import { Routes, RouterModule } from '@angular/router';

import { LandingPageContainerComponent } from './landing-page-container/landing-page-container.component';

export const appRoutes: Routes = [
    { path: '', component: LandingPageContainerComponent},
    // { path: '404', component: ErrorComponent},
    // otherwise redirect to home
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

/* export const routes = RouterModule.forRoot(appRoutes, { useHash: false }); */
