import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LandingPageContainerComponent } from './landing-page-container/landing-page-container.component';
import { HeaderComponent } from './components/header/header.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { DownloadInstructionsComponent } from './components/download-instructions/download-instructions.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { AndroidComponent } from './components/download-instructions/android/android.component';
import { IosComponent } from './components/download-instructions/ios/ios.component';
import { appRoutes } from './app.routing';
import { RouterModule } from '@angular/router';
import { CssVariablesString } from './_css-variables';
import { LoadingComponent } from './components/loading/loading.component';
import { CustomTranslateLoader } from './_services/custom-translate-loader.service';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    LandingPageContainerComponent,
    HeaderComponent,
    FaqsComponent,
    DownloadInstructionsComponent,
    ContactsComponent,
    FooterComponent,
    AndroidComponent,
    IosComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    NgbCollapseModule,
    RouterModule.forRoot(appRoutes, { useHash: false }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })],
  providers: [
    CssVariablesString
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }

/* export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
} */

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
  // return new TranslateHttpLoader(http, 'https://gaminngeneric.blob.core.windows.net/gaminn-generic/BackOffice_Assets/', '.json');
}
