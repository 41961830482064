export const environment = {
  production: true,
  envName: 'EstorilOpen',

  apiUrl: 'https://estoril-open-bo-api-prod.azurewebsites.net/',
  appId: 'tech.innowave.estorilopen',
  translations_url: 'https://estorilopendevglobal.blob.core.windows.net/global/Landing_Page_Translations/',

  showDownloadInstructions: false
};
