export class CssVariablesString {

  cssAsText  = '#VariableCSSInit { } ' +

    // ################## Variable Colors Classes ##################
    '.branding-background-color { background-color: BrandingColor !important } ' +
    '.branding-border-color { border-color: BrandingColor !important } ' +
    '.branding-font-color { color: BrandingColor !important } ' +
    '.darker-background-color { background-color: DarkerColor !important }';
}
