import { Component, OnInit } from '@angular/core';
import { LandingPageContainerService, Language } from '../_services/landing-page-container.service';
import { ActivatedRoute } from '@angular/router';
import { CssVariablesString } from '../_css-variables';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-landing-page-container',
  templateUrl: './landing-page-container.component.html',
  styleUrls: ['./landing-page-container.component.scss']
})
export class LandingPageContainerComponent implements OnInit {

  infoLoaded: boolean;
  appId: string;
  cssAsText: string;

  environment = environment;

  constructor(
    private landingPageContainerService: LandingPageContainerService,
    private route: ActivatedRoute,
    private cssVariablesString: CssVariablesString,
    private titleService: Title
  ) {
    this.cssAsText = cssVariablesString.cssAsText;
    this.appId = environment.appId;
  }

  ngOnInit() {
    this.landingPageContainerService.getConfigs(this.appId).subscribe(resp => {
      this.setCssVariables();
      document.getElementsByTagName('style')[0].innerHTML =
        document.getElementsByTagName('style')[0].innerHTML.split('#VariableCSSInit { }')[0] + this.cssAsText;
      this.titleService.setTitle(this.landingPageContainerService.generalConfigs.AppName);

      this.landingPageContainerService.getLanguages(this.appId).subscribe(langs => {
        const defaultLang: Language = langs.filter(lang => lang.IsDefault)[0];
        this.landingPageContainerService.defaultLanguage = defaultLang.Code;
        this.infoLoaded = true;
      });
    });
  }

  setCssVariables() {
    this.cssAsText =
      this.cssVariablesString.cssAsText.split('BrandingColor').join(this.landingPageContainerService.generalConfigs.BrandingColor);
    this.cssAsText =
      this.cssAsText.split('DarkerColor').join(this.landingPageContainerService.generalConfigs.DarkerColor);
  }

}
