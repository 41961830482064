import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LandingPageContainerService } from 'src/app/_services/landing-page-container.service';

@Component({
  selector: 'app-ios',
  templateUrl: './ios.component.html',
  styleUrls: ['./ios.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class IosComponent implements OnInit {

  constructor(
    public landingPageContainerService: LandingPageContainerService
    ) { }


  ngOnInit() {
  }

  downloadFile(url) {
    if (url === '') {
      alert ('missing url');
      return;
    }
    const link = document.createElement('a');
    link.href = url;
    link.click();
  }


}
