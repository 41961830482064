import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './_services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private translate: TranslateService, public apiService: ApiService) {
    translate.setDefaultLang('pt');
  }
}
