import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LandingPageContainerService {

  GENERIC_ERROR = 'An error occured. Please try again later.';
  SUCCESS_CODE = 0;

  defaultLanguage: string;
  generalConfigs: Configs = null;

  constructor(private apiService: ApiService) { }

  getConfigs(appId: string): Observable<ConfigsResponse> {
    const body = {
      Input: {},
      UserData: {
        AppID: appId
      }
    };
    return this.apiService
      .post('General/GetLandingPageConfigurations', body).pipe(
        catchError(error => {
          throw this.GENERIC_ERROR;
        }),
        map(
          (data: ConfigsResponse) => {
            if (!this.isValidServerReply(data)) {
              throw this.GENERIC_ERROR;
            }
            if (data.Code !== this.SUCCESS_CODE) {
              throw data.Message;
            }

            this.generalConfigs = data.Container;
            return data;
          },
          err => {
            return err;
          }
        ));
  }

  getLanguages(appId: string): Observable<Array<Language>> {
    const body = {
      Input: {},
      UserData: {
        AppID: appId
      }
    };
    return this.apiService
      .post('General/GetBOLanguages', body).pipe(
        catchError(error => {
          throw this.GENERIC_ERROR;
        }),
        map(
          (data: LanguagesResponse) => {
            if (!this.isValidServerReply(data)) {
              throw this.GENERIC_ERROR;
            }
            if (data.Code !== this.SUCCESS_CODE) {
              throw data.Message;
            }

            return data.Container;
          },
          err => {
            return err;
          }
        ));
  }

  isValidServerReply(data): boolean {
    return data.hasOwnProperty('Code') && data.hasOwnProperty('Message');
  }

}

export class ConfigsResponse {
  Container: Configs;
  Code: number;
  Message: string;
}

export class LanguagesResponse {
  Container: Array<Language>;
  Code: number;
  Message: string;
}

export class Configs {
  BrandingImage: string;
  AppMainImage: string;
  AppTiltedImage: string;
  IOsStep1: string;
  IOsStep2: string;
  IOsStep3: string;
  AndroidStep1: string;
  AndroidStep2: string;
  AndroidStep3: string;
  EmailImage: string;
  SecondaryBrandingImage: string;
  BrandingColor: string;
  DarkerColor: string;
  Step1: string;
  Step2: string;
  Step3: string;
  Step4: string;
  Passo1: string;
  Passo2: string;
  Passo3: string;
  Passo4: string;
  ContactEmail: string;
  AndroidInstallUrl: string;
  IOSInstallUrl: string;
  AppName: string;
  RegisterPoints: number;
  BackofficeUrl: string;
}

export class Language {
  LanguageId: string;
  Code: string;
  Name: string;
  Image: string;
  IsDefault: boolean;
}

