import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LandingPageContainerService } from 'src/app/_services/landing-page-container.service';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    public landingPageContainerService: LandingPageContainerService
    ) { }

  ngOnInit() {
  }

  sendEmail() {
    const email = this.landingPageContainerService.generalConfigs.ContactEmail;
    let subject = '';

    this.translate.get('contacts.subject').subscribe(translatedSubject => {
      subject = translatedSubject;
    });
    const mailElement = document.createElement('a');
    mailElement.href = 'mailto:' + email + '?subject=' + subject;
    mailElement.click();
  }

}
