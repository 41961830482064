import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export class CustomTranslateLoader implements TranslateLoader  {
    contentHeader = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8', 'Access-Control-Allow-Origin': '*'});
    constructor(private http: HttpClient) {

    }
    /**
     * Gets the translations from the server
     * @param lang
     * @returns {any}
     */
    getTranslation(lang: string): Observable<any> {
        const apiAddress = environment.translations_url + lang + '.json?1500';
        return Observable.create(observer => {
            this.http.get(apiAddress, { headers: this.contentHeader}).subscribe((res: Response) => {
                observer.next(res);
                observer.complete();
            },
            error => {
                this.http.get(`/assets/i18n/${lang}.json`).subscribe((res: Response) => {
                    observer.next(res);
                    observer.complete();
                });
            });
        });
    }
}
