import { Component, OnInit } from '@angular/core';
import { LandingPageContainerService } from 'src/app/_services/landing-page-container.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  public isCollapsed = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;
  public isCollapsed4 = true;
  public isCollapsed5 = true;

  constructor(
    public landingPageContainerService: LandingPageContainerService
  ) { }

  ngOnInit() {
  }

  t(event) {
    console.log(event);
    event.target.innerHtml = 'Resposta';
    console.log(event.target);
  }

}
